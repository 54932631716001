import { useEffect, useRef, useState } from 'react';

const useBurgerMenu = () => {
    const [isOpen, setIsOpen] = useState(false);
    const closeTimeoutRef = useRef<NodeJS.Timeout|null>(null);

    const handleBurgerEnter = () => {
        if (closeTimeoutRef.current) {
            clearTimeout(closeTimeoutRef.current);
        }

        setIsOpen(true);
    };

    const handleBurgerLeave = () => {
        closeTimeoutRef.current = setTimeout(() => {
            setIsOpen(false);
        }, 2000);
    };

    const handleMenuEnter = () => {
        if (closeTimeoutRef.current) {
            clearTimeout(closeTimeoutRef.current);
        }
    };

    useEffect(() => {
        const body = document.querySelector('body');
        const handleClick = (e: MouseEvent) => {
            e.stopPropagation();

            if (e.target) {
                if (isOpen) {
                    setIsOpen(false);
                }
            }
        };

        if (body) {
            body.addEventListener('click', handleClick);
        }

        return () => {
            if (body) {
                body.removeEventListener('click', handleClick);
            }
        };
    }, []);

    return {
        isOpen,
        setIsOpen,
        handleBurgerEnter,
        handleBurgerLeave,
        handleMenuEnter,
    };
};

export default useBurgerMenu;
