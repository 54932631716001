import { useState, useContext } from 'react';
import classes from './services.module.scss';
import ArrowBottomIcon from '@icons/ArrowBottomIcon';
import { GalleryContext } from '~/context/gallery/galleryContext';
import { buildApiString, formatNumberPrice } from '~/shared/lib/helpers';
import { useTranslation } from 'react-i18next';
import { EYMActions } from '../../processes/providers/yandexMetrika/entity';
import { useYandexHit } from '../../processes/providers/yandexMetrika/hooks/useYandexHit';
import type { IServiceWithColor } from './entity';
import { EServiceStatus, SERVICE_STATUS } from './entity';

interface IServiceProps {
    service: IServiceWithColor;
    onlyName?: boolean;
}

const Service = ({ service, onlyName = false }: IServiceProps) => {
    const [showDescription, setShowDescription] = useState<boolean>(false);
    const { show } = useContext(GalleryContext);
    const { t } = useTranslation('components');
    const { ymHit } = useYandexHit();
    const [imageServicePreview] = (service.img_links || []);

    const statusId = (service.status_id || EServiceStatus.NEW);
    const statusData = SERVICE_STATUS[statusId];

    const handleServicePhotoClick = (img_links: any) => {
        show(img_links);

        ymHit(EYMActions.SERVICE_PHOTO_PREVIEW);
    };

    return (
        <tr className={`${classes.item} ${showDescription ? classes.isOpen : ''} ${service.colored ? classes.colored : ''}`}>
            <td className={classes.heading}>
                {service.img_links && (
                    <div
                        onClick={() => show(service.img_links)}
                        className={classes.mobilePreview}
                        style={{ backgroundImage: `url(${buildApiString(imageServicePreview.url)})` }}
                    />
                )}
                <div className={classes.title}>
                    <span className={`${classes.desktop} ${onlyName && classes.bold}`}>
                        {service.name}
                    </span>
                    {service.img_links && onlyName && (
                        <button
                            onClick={() => show(service.img_links)}
                            className={classes.photo_button}
                        >
                            {t('services.showPhotoButtonDesktop')}
                        </button>
                    )}
                    <span className={classes.mobile}>{service.name}</span>
                </div>
                <button
                    onClick={() => setShowDescription(!showDescription)}
                    className={classes.showMore}
                >
                    <ArrowBottomIcon />
                </button>
            </td>
            <td className={classes.data}>
                { !onlyName && (
                    <>
                        <span className={classes.mobileLabel}>
                            {t('services.tableItems.status')}
                        </span>
                        <div>
                            <span>{t(statusData.title)}</span>
                        </div>
                    </>
                )}
            </td>
            <td className={classes.data}>
                {!onlyName && (
                    <>
                        <span className={classes.mobileLabel}>
                            {t('services.tableItems.amount')}
                        </span>
                        <span>
                            {service.number_of_units} { service.unitname ?? t('services.tableItems.namePiece') }
                        </span>
                    </>
                )}
            </td>
            <td className={classes.data}>
                {!onlyName && (
                    <>
                        <span className={classes.mobileLabel}>
                            {t('services.tableItems.price')}
                        </span>
                        <span>
                            {formatNumberPrice(service.price, '')}
                        </span>
                    </>
                )}
            </td>
            <td className={classes.data}>
                {!onlyName && (
                    <>
                        <span className={classes.mobileLabel}>
                            {t('services.tableItems.discount')}
                        </span>
                        <span>
                            {service.discount}%
                        </span>
                    </>
                )}
            </td>
            <td className={classes.data}>
                {!onlyName && (
                    <>
                        <span className={classes.mobileLabel}>
                            {t('services.tableItems.orderSum')}
                        </span>
                        <span>
                            {formatNumberPrice(Number(service.sum_service.toFixed(2)), '')}
                        </span>
                    </>
                )}
            </td>
            <td className={classes.previewBox}>
                {service.img_links && !onlyName && (
                    <div
                        onClick={() => handleServicePhotoClick(service.img_links)}
                        className={classes.preview}
                        style={{ backgroundImage: `url(${buildApiString(imageServicePreview.url)})` }}
                    />
                )}
            </td>
        </tr>
    );
};

export default Service;
