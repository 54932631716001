import BurgerButton from './BurgerButton';
import classes from './Burger.module.scss';
import MenuItem from './MenuItem';
import MenuContainer from './MenuContainer';
import CloseIcon from '~/components/Icons/CloseIcon';
import { useTranslation } from 'react-i18next';
import useBurgerMenu from '../hooks/useBurgerMenu';
import useReferralSystem from '../hooks/useReferralSystem';

const BurgerMenu = () => {
    const { t } = useTranslation('components');
    const {
        isOpen,
        handleMenuEnter,
        setIsOpen
    } = useBurgerMenu();
    const { handleReferralSystemNavigate } = useReferralSystem();

    return (
        <div className={classes.container}>
            <BurgerButton onClick={() => setIsOpen(!isOpen)} />
            <div
                className={`${classes.dropdown} ${isOpen ? classes.active : ''}`}
                onMouseEnter={handleMenuEnter}
                onMouseLeave={() => setIsOpen(false)}
            >
                <MenuContainer>
                    <div className={classes.head}>
                        <button onClick={() => setIsOpen(false)}>
                            <CloseIcon />
                        </button>
                    </div>
                    <MenuItem clickHandler={() => handleReferralSystemNavigate()} label={t('burgerMenu.referralSystem')} />
                </MenuContainer>
            </div>
        </div>
    );
};

export default BurgerMenu;
