import { useStore } from '~/shared/hooks/useStore';

const useReferralSystem = () => {
    const { CCompanyInfo, CLinks } = useStore();
    const { referal } = CLinks.links;

    const handleReferralSystemNavigate = () => {
        const { country_id } = CCompanyInfo.companyInfo;

        if (country_id === '4') {
            return window.location.href = `https://ref.agb.is/${referal}`;
        } else {
            return window.location.href = `https://ref.dry.care/${referal}`;
        }
    };

    return {
        handleReferralSystemNavigate,
    };
};

export default useReferralSystem;
