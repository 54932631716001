import classes from './Burger.module.scss';

interface IProps {
  clickHandler: () => void;
  label: string;
}

const MenuItem = ({ clickHandler, label }: IProps) => (
    <li onClick={clickHandler} className={classes.menuItem}>
        <span>{label}</span>
    </li>
);

export default MenuItem;
