import classes from './Burger.module.scss';

interface IProps {
    children: React.ReactNode;
}

const MenuContainer = ({ children }: IProps) => (
    <ul className={classes.menuContainer}>
        { children }
    </ul>
);

export default MenuContainer;
