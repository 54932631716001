import Section from '@components/Section/Section';
import Payment from '@components/Payment/Payment';
import ButtonBack from '@components/ButtonBack/ButtonBack';
import { observer } from 'mobx-react';
import { useStore } from '~/shared/hooks/useStore';

const PaymentPage = () => {
    const { CClientInfo, COrder } = useStore();
    const { deposit, bonus } = CClientInfo.clientInfo;
    const { order_info } = COrder.order;
    return (
        <Section zeroPadding={true}>
            <ButtonBack tabletHidden={true} mobileHidden={true} href='/confirmed' />
            <Payment
                orderNumber={order_info.doc_num}
                orderSum={(Number(order_info.order_sum.toFixed(2)))}
                deposit={deposit}
                bonus={bonus}
                orderDebt={(Number(order_info.need_to_pay.toFixed(2)))}
            />
        </Section>
    );
};

export default observer(PaymentPage);
