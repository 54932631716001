const BurgerButton = ({ ...props }: React.ButtonHTMLAttributes<HTMLButtonElement>) => (
    <button {...props}>
        <svg width="36" height="18" viewBox="0 0 36 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="1" y1="1" x2="35" y2="1" stroke="black" strokeWidth="2" strokeLinecap="round"/>
            <line x1="1" y1="9" x2="35" y2="9" stroke="black" strokeWidth="2" strokeLinecap="round"/>
            <line x1="1" y1="17" x2="35" y2="17" stroke="black" strokeWidth="2" strokeLinecap="round"/>
        </svg>
    </button>
);

export default BurgerButton;
